import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslations } from '../hooks/use-translations'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import List from '../components/Common/List'
import { Button } from '../components/Common/Button'
import ModalIframe from '../components/Common/ModalIframe'

import { getIframeYoutubeSrc } from '../utils'
import YoutubePlayIcon from '../svg/youtube-play-icon'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'
import TryForFreeForm from '../components/contact-form/TryForFreeForm'

const BuildingSmartWebinar: React.FC = () => {
  const { t } = useTranslations()
  const [modal, setModal] = useState(false)
  const [modalVideo, setModalVideo] = useState(false)
  return (
    <>
      <SEO
        title="Buildingsmart and SIGNAX webinar: Unlocking the Value of BIM During Construction Stage"
        description="In this online webinar, you will know how BIM & digital data can be used for construction site goals and how OpenBIM standards can help in reach this."
      />
      <NewsSectionBlock date="30.01.2024">
        <Typography variant="h1" color="blue">
          {`Buildingsmart and SIGNAX webinar:\n Unlocking the Value of BIM During Construction Phase`}
        </Typography>
        <Typography variant="body1" color="blue">
          <strong>January, 24. 2024</strong>
        </Typography>
        <div className={s.youtubeVideo} onClick={() => setModalVideo(true)}>
          <StaticImage
            width={680}
            src="../assets/images/news/building-smart-webinar/video-preview.png"
            alt="The Future is here"
            title="The Future is here"
            placeholder="blurred"
          />
          <YoutubePlayIcon />
        </div>
        <Typography variant="body1">
          SIGNAX experts provided a webinar on the buildingSMART UAE platform.
          From this recording, you will know how BIM & digital data can be used
          for construction site goals and how OpenBIM standards can help in
          reach this. You will learn about:
        </Typography>
        <List className="pl-4">
          <li>
            Choosing the right technologies for your workflows and BIM uses;
          </li>
          <li>
            Making the Federated model. Clash detection and data check before
            going to field with it;
          </li>
          <li>Collecting high-quality digital data from the site;</li>
          <li>
            Data calibration on site against the design models (Check - Accept -
            Save);
          </li>
          <li>Progress tracking;</li>
          <li>Scope and QA/QC management;</li>
          <li>Analytics and construction dashboards from BIM data.</li>
        </List>
        <Typography variant="body1">
          Webinar will be interesting for acting BIM managers and coordinators,
          Digital team, Project managers, Construction managers, Scope and QA/QC
          engineers, planning engineers, and other roles.
        </Typography>
        <div className="w-100 flex flex-col items-center">
          <Button size="large" onClick={() => setModal(true)}>
            Try for free
          </Button>
        </div>
      </NewsSectionBlock>
      {modal && (
        <TryForFreeForm
          submitBtnText={t('Get login and password')}
          onClose={() => setModal(false)}
          title={t('Try for free')}
        />
      )}
      {modalVideo && (
        <ModalIframe
          title="Webinar video"
          src={getIframeYoutubeSrc('FSxRmZ7De_4')}
          srcTitle="BuildingSMART and SIGNAX webinar - Unlocking the Value of BIM During Construction Phase"
          onClose={() => setModalVideo(false)}
        />
      )}
    </>
  )
}

export default BuildingSmartWebinar
